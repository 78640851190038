(function ($) {
  /**
   * Basic sub-block toggle for gnav utility templates
   * inside this formatter
   * sub-templates need to have a trigger with class .js-gnav-util-trigger
   * the following div should have .js-gnav-util__content .hidden
   *
   * NOTE: the cart utility link manages its' own toggle so we can render
   * cart content via js when opening
   * ~/kilian_base/template_api/navigation/gnav_cart_v1/gnav_cart_v1.js
   *
   */
  Drupal.behaviors.utilityNavFormatter = {
    attach: function (context) {
      var $block = $('.gnav-util-formatter__item', context);
      var $trigger = $('.js-gnav-util-trigger', $block);
      var $sectionNav = $('.site-header__section--nav__mobile-animate', context);
      var $menuTrigger = $('.js-gnav-mobile-trigger', $block);
      var $menuTitleTrigger = $('.menu-ref__title, .menu__item--category-expandable', context);
      var $menuClose = $('.menu-ref--close', context);
      var $closeTrigger = $('.js-gnav-util-close', $block);
      var $blockContent = $('.js-gnav-util__content', $block);
      var $body = $('body', context);
      var $contentDim = $body.find('.js-content-dim');
      var isMobile = site.client.isMobile;
      var isiPad = navigator.userAgent.match(/iPad/i) ? true : false;
      var $currentBackTitle;
      var $searchNavInput = $('.js-search-term--nav', context);
      var unisonIsMobile = parseInt(Unison.fetch.now().width) <= parseInt(Unison.fetch.all().medium);
      // desktop is non-mobile + non-tablet
      var unisonIsDesktop = parseInt(Unison.fetch.now().width) >= parseInt(Unison.fetch.all().large);

      if (unisonIsDesktop) {
        // mouseover shows search form
        $('.gnav-util__icon--search.js-gnav-util-trigger', $block).on('mouseover', function (event) {
          // already active.
          if ($body.hasClass('search-active')) {
            return;
          }

          var $search_trigger = $(this);

          $search_trigger.click();
          $body.addClass('search-active-mouseover');

          // momentarily block clicking the icon to repvent misclicks
          $body.addClass('search-active-mouseover-block');
          window.setTimeout(function () {
            $body.removeClass('search-active-mouseover-block');
          }, 1000);
        });
      }

      // match ipad (768px and above)
      var unisonIsTablet = parseInt(Unison.fetch.now().width) >= parseInt(Unison.fetch.all().medium);

      if (unisonIsTablet) {
        // remove title so it doesnt trigger on hover
        $('a.gnav-util__icon').each(function (i, obj) {
          var $link = $(obj);

          $link.attr('title', '');
        });
      }

      // add third teir menu for mobile
      $('.menu__item--category-expandable').each(function () {
        var mobileView =
          matchMedia('screen and (max-width:768px)').matches || matchMedia('screen and (max-width:1024px)').matches;

        if (unisonIsMobile || isiPad || mobileView) {
          var $thirdTierWrapper = null;

          $thirdTierWrapper = $(
            '<div class="menu-ref__content"><div class="menu--lvl-4"><ul class="menu menu--lvl-5"></ul></div></div>'
          );
          while (!$(this).next().hasClass('menu__item--category') && $(this).next().hasClass('menu__item--link')) {
            var $nextItem = $(this).next();

            $thirdTierWrapper.find('.menu--lvl-5').append($nextItem);
          }
          $(this).after($thirdTierWrapper);
        }
      });

      $trigger.on('click', function (event) {
        event.preventDefault();
        var $content = $(this).next('.js-gnav-util__content');
        var already_active = !$content.hasClass('hidden');
        var mouseover_active = $body.hasClass('search-active-mouseover');
        var mouseover_active_block = $body.hasClass('search-active-mouseover-block');

        // if initiated with mouseover. click does not close
        if (mouseover_active_block) {
          return;
        }

        $body.removeClass('mobile-gnav-active search-active');
        $blockContent.not($(this).next('.js-gnav-util__content')).addClass('hidden');

        // eh gnav
        if ($(this).hasClass('gnav-util__icon--search')) {
          if (already_active) {
            $('body').removeClass('search-active search-active-mouseover');
          } else {
            $body.addClass('search-active');
            $searchNavInput.blur().val('').focus();
            window.setTimeout(function () {
              // can't focus until visible
              $searchNavInput.get(0).focus();
            }, 100);
          }
        }
        $(this).next('.js-gnav-util__content').toggleClass('hidden');
      });

      // hamburger adds a body class so we can toggle main nav
      $menuTrigger.once().on('click', function (event) {
        event.preventDefault();
        $blockContent.addClass('hidden');
        $(document).trigger('endeca.typeahead.clearall');
        $('body').toggleClass('mobile-gnav-active');
        $menuClose.show();
      });

      $menuTitleTrigger.on('click', function (event) {
        var $title = $(this);
        var $title_link = $title.find('.menu-ref__link');
        var $content = $title.siblings('.menu-ref__content');
        var hasContent = $.trim($content.html()) ? true : false;
        var mobileView =
          matchMedia('screen and (max-width:768px)').matches || matchMedia('screen and (max-width:1024px)').matches;

        if (unisonIsMobile || isiPad || mobileView) {
          if ($title_link.length && !hasContent) {
            window.location = $title_link.attr('href');

            return;
          }
          event.preventDefault();
          var leftAnimate;
          var menuLevel;

          if ($(this).hasClass('menu-ref__title')) {
            leftAnimate = 111;
            menuLevel = 2;
          } else {
            leftAnimate = 222;
            menuLevel = 4;
          }

          $('.menu-ref__content')
            .find('.menu--lvl-' + menuLevel)
            .hide();
          $currentBackTitle = $content.find('.menu__item--back');
          $nextLevel = $($(this).next('.menu-ref__content')[0]).find('.menu--lvl-' + menuLevel + ':first-child');
          $nextLevel.show();
          leftPosition = $sectionNav.position().left - leftAnimate + '%';
          $sectionNav.animate({ left: leftAnimate * -1 + '%' }, 250);

          // add mobile back title
          if (!$nextLevel.find('.menu__item--back').length) {
            $backTitle = $(
              '<li class="menu__item menu__item--lvl-3 menu__item--link menu__item--back menu__item--back-lvl--' +
                menuLevel +
                '"></li>'
            );
            $backTitle.html($(this).find('a').html() || $(this).find('span').html() || $(this).html());
            $($nextLevel.find('.menu--lvl-3, .menu--lvl-5')[0]).prepend($backTitle);

            $backTitle.on('click', function () {
              // $sectionNav.removeClass("nav-indent--1");
              var backAnimate = $(this).hasClass('menu__item--back-lvl--4') ? 111 : 0;

              leftPosition = backAnimate * -1 + '%';
              $sectionNav.animate({ left: leftPosition }, 250);
              $nextLevel.hide();
            });
          }
        }
      });

      if (!unisonIsMobile) {
        $menuTitleTrigger.on('mouseenter', function (event) {
          // hide other navs
          if ($(this).next('.menu-ref__content').length) {
            $blockContent.addClass('hidden');
            $body.removeClass('gnav-active search-active');
          }
        });
      }

      $menuClose.on('click', function (event) {
        event.preventDefault();
        $('body').removeClass('mobile-gnav-active search-active');
        $menuClose.hide();
        if ($currentBackTitle.is(':visible')) {
          $currentBackTitle.trigger('click');
        }
      });

      $(document).on('gnav-util-close-all', function () {
        $('body').removeClass('search-active');
        $('body').removeClass('search-active-mouseover');
        $('.js-gnav-util__content').addClass('hidden');
      });
      // content block close
      // js-gnav-util-close
      $closeTrigger.on('click', function (event) {
        event.preventDefault();
        $(document).trigger('gnav-util-close-all');
      });

      // clicking overlay closes
      $('.js-content-dim').click(function () {
        event.preventDefault();
        $(document).trigger('gnav-util-close-all');
      });

      // mouseover closes when started by mouseover
      $('.js-content-dim').on('mouseover', function () {
        if ($body.hasClass('search-active-mouseover')) {
          event.preventDefault();
          $(document).trigger('gnav-util-close-all');
        }
      });
    }
  };
})(jQuery);
